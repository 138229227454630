import React from "react";

const Q_NUM = 10;
const Q_TYPE = 2;
const apiURL = process.env.REACT_APP_API_URL

export default class StartPage extends React.Component{
    startTesting() {   
        localStorage.setItem("q_num", Math.floor(Math.random() * Q_NUM) + 1);
        localStorage.setItem("q_type", Math.floor(Math.random() * Q_TYPE));
        
        console.log("question number: ", localStorage.getItem("q_num"));
        console.log("question type(0 for WEAK, 1 for STRONG): ", localStorage.getItem("q_type"));
        
        // ask for a key (a unique id generated by MySQL) from the backend
        // replace '/create_test' with 'http://{your_ip}:{your_port}/create_test' 
        const fetchExamKey = async () => {
            // console.log(Date.now());
            const response = await fetch(apiURL+'/create_test', {
                method: 'POST',
            });
            const data = await response.json();
            console.log(data[0], data[1]);
	
            return [data[0], data[1]];
            // try {
            //         const response = await fetch('/create_test', {
            //             method: 'POST',
            //         });
            //         const data = await response.json();
            //         console.log(data[0], data[1]);
            //         return [data[0], data[1]];
            // } catch (error) {
            //     throw new Error('Failed to fetch exam key');
            // }
        };

        const jumpNext = (page) => {            
            const a = document.createElement('a');
            a.href = page;   
            console.log("to jump to the next page!");         
            a.click();
        }

        fetchExamKey()
        .then((id_result) => {
            localStorage.setItem("id_A", id_result[0]);
            localStorage.setItem("id_B", id_result[1]);
            
            console.log("id A:", localStorage.getItem("id_A"));
            console.log("id B:", localStorage.getItem("id_B"));

            jumpNext("/partA/introduction");
        })
        .catch((error) => {
            console.error(error);
            jumpNext("/partA/introduction");
        });
    }

    

    render() {
        return (
            <div>
                {/* <p>介绍Part A, Part B的考试内容 结束后会产生report</p> */}
                <div className="heading">
                    <p className="part">Instruction</p>

                    
                    <div className="part_start_intro">
                        <p className="subtitle">Part A: Group Discussion (preparation: 10 minutes; discussion: 8 minutes per group of four candidates)</p>
                        <p className="content">
                            Four candidates will be grouped together and will take part in a group discussion based on a given short text. These texts may include advertisements, book synopses, film reviews, letters, short news reportsand so on. Candidates may be required to make suggestions, give advice, make and explain a choice, argue for and/or against a position, or discuss the pros and cons of a proposal.
                        </p>
                        <p className="content">
                            Candidates will be given ten minutes for preparation and will be allowed to make notes. During the discussion they may refer to their notes.
                        </p>
                    </div>
                    
                    <div className="part_start_intro">
                        <p className="subtitle">Part B: Individual Response (one minute per candidate)</p>
                        <p className="content">
                            Each candidate will respond individually to an examiner's question(s), which will be based on the group discussion task. Candidates may be required to make and justify a choice, decide on and explain a course of action, argue for or against a position, and so on.
                        </p>
                    </div>
                </div>
                <div className="board">
                    <div className="button_container">
                        <button className="button" onClick={this.startTesting}>
                            Start
                        </button>
                    </div>
                    {/* <div className="video_container">
                        <div className="camera_subcontainer">
                            <video id="video_player" src={mVideo} preload="auto"></video>
                            
                        </div>
                    </div> */}
                </div>
            </div>
        );
    }
}
