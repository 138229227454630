// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.waiting_dialog {
    width: auto;
    height: auto;
    position: fixed;
    top: 46%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 10px;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}


.waiting_dialog_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 20px;
    margin-bottom: 35px;
    text-align: center;
}


.spinner {
    margin-top: 5px;
    width: 32px;
    height: 32px;
    border: 9px solid rgb(237, 239, 239);
    border-top: 9px solid darkcyan;
    border-radius: 50%;
    animation: spin 1.5s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/css/wait_dialog.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,uBAAuB;IACvB,mBAAmB;;IAEnB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;AAClB;;;AAGA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;AACtB;;;AAGA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,8BAA8B;IAC9B,kBAAkB;IAClB,oCAAoC;AACxC;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".waiting_dialog {\n    width: auto;\n    height: auto;\n    position: fixed;\n    top: 46%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background-color: white;\n    border-radius: 10px;\n    \n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    z-index: 10000;\n}\n\n\n.waiting_dialog_container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    margin-left: 50px;\n    margin-right: 50px;\n    margin-top: 20px;\n    margin-bottom: 35px;\n    text-align: center;\n}\n\n\n.spinner {\n    margin-top: 5px;\n    width: 32px;\n    height: 32px;\n    border: 9px solid rgb(237, 239, 239);\n    border-top: 9px solid darkcyan;\n    border-radius: 50%;\n    animation: spin 1.5s linear infinite;\n}\n\n@keyframes spin {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
